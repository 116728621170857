<template>
	<div style="display: inline-block;">
		<my-dialog v-model="dialog" max-width="600" v-bind="$attrs" title="Приключване на станция">
			<v-container>
				<v-form v-model="isFormValid">
					<v-text-field
						v-model="undersigned" label="Име (домоуправител/ представител на ЕС)" outlined dense
						:rules="[Validators.required()]" />
					<div class="">Подпис:</div>
					<signature-pad
						v-model="signatureSvg"
						ref="signature"
						:is-signed="isSigned"
						width="100%" height="320px"
						required
						:rules="[Validators.required()]"
					/>
				</v-form>
			</v-container>

			<template #actions>
				<v-btn small @click="clearSignature" :disabled="signatureSvg === ''">
					Нов подпис
				</v-btn>
				<v-spacer/>
				<v-btn small text @click="dialog = false">Откажи</v-btn>
				<v-btn :disabled="sending || !isFormValid" small color="primary" @click="tryToCreateFindingsProtocol">Приключи</v-btn>
			</template>
		</my-dialog>

		<v-snackbar color="success" v-model="snackbar" timeout="2000">
			Успех!
		</v-snackbar>

	</div>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import Validators from "@/ittijs/Validators";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";
import ThrowMeMixin from "@/ittijs/ThrowMeMixin";
import IModelMixin from "@/ittijs/IModelMixin";

export default {
	computed   : {
		Validators() {
			return Validators
		}
	},
	components : {SignaturePad, MyDialog},
	mixins: [
		IModelMixin,
		ThrowMeMixin,
	],
	props: {
		stationYearlyOtchetId: {},
		imotListWithEmptyData: {},
	},
	data(){
		return {
			isSigned: false,
			dialog: false,
			snackbar: false,
			isFormValid: false,
			sending: false,
			signatureSvg: null,
			undersigned: null,
		}
	},
	methods: {
		open(){ // called from outside
			if(this.imotListWithEmptyData.length){
				this.dialog = true;
				return;
			}
			this.createFindingsProtocol();
		},
		createFindingsProtocol() {
			this.sending = true;
			return this.imodel.finishStation(this.stationYearlyOtchetId, this.undersigned, this.signatureSvg)
				.then(() => {
					this.sending = false;
					this.dialog = false;
					this.snackbar = true;
					this.$emit('ready');
				})
				.catch(res => {
					this.sending = false;
					this.throwMe(res);
				});
		},
		tryToCreateFindingsProtocol() {
			if (!this.isFormValid) {
				return;
			}
			this.createFindingsProtocol();
		},
		clearSignature() {
			this.$refs.signature.clearSignature();
		},
	},
}
</script>