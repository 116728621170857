import {ITTIModelBase} from '@/ittijs/ITTIModel';

class Base extends ITTIModelBase {
	getCurrentSession(utility) {
		return this.fetch('getCurrentSession', {utility});
	}
	getDates(utility, session, progress) {
		return this.fetch('getDates', {utility, session, progress});
	}
	getDailySchedule(utility, session, date, progress){
		return this.fetch('getDailySchedule', {utility, session, date, progress});
	}
	getStationView(utility, session, date, station){
		return this.fetch('getStationView', {utility, session, date, station});
	}
	getImotView(utility, session, date, station, imot){
		return this.fetch('getImotView', {utility, session, date, station, imot});
	}

	startImot(utility, session, date, station, imot) {
		return this.fetch('startImot', {utility, session, date, station, imot});
	}

	updateImot(imotYearlyOtchetId, deviceData, notes) {
		return this.fetch('updateImot', {imotYearlyOtchetId, deviceData, notes});
	}

	sendYearlyOtchetEmail(imotYearlyOtchetId, signatureSvg, emailAddress) {
		return this.fetch(
			'sendYearlyOtchetEmail',
			{imotYearlyOtchetId},
			{signatureSvg, emailAddress}
		);
	}

	createProtocolFindings(utility, session, date, station, imot, findings, signatureSvg) {
		return this.fetch(
			'createProtocolFindings',
			{utility, session, date, station, imot},
			{findings, signatureSvg}
		);
	}

	setImotStatus(utility, session, date, station, imot, status) {
		return this.fetch(
			'setImotStatus',
			{utility, session, date, station, imot},
			{status}
		);
	}

	reopenImot(imotYearlyOtchetId) {
		return this.fetch('reopenImot', {imotYearlyOtchetId});
	}

	finishStation(stationYearlyOtchetId, undersigned, signatureSvg) {
		return this.fetch(
			'finishStation',
			{stationYearlyOtchetId},
			{undersigned, signatureSvg}
		);
	}

	reopenStation(stationYearlyOtchetId) {
		return this.fetch('reopenStation', {stationYearlyOtchetId});
	}
}

export default class Godishen extends Base {
	constructor() {
		super('OtchetnikUIGodishen');
	}
}

export class Mesechen extends Base {
	constructor() {
		super('OtchetnikUIMesechen');
	}
}
