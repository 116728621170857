<template>
	<v-form ref="form">
		<v-container fluid>

			<v-row dense>
				<v-col cols="12">
					<v-textarea
						outlined dense label="Констатация" :rules="[Validators.required()]"
						v-model="findings"
					/>
				</v-col>
			</v-row>

			<div class="">Подпис:</div>
			<signature-pad
				v-model="signatureSvg"
				ref="signature"
				:is-signed="isSigned"
				@update:isSigned="$emit('update:isSigned', $event)"
				width="100%" height="320px"
				required
			/>
			<div class="text-right mt-2" v-if="!hideClearSignature">
				<v-btn small color="primary" @click="clearSignature" :disabled="!isSigned">
					Изчисти
				</v-btn>
			</div>

		</v-container>
	</v-form>
</template>

<script>
import Validators from "@/ittijs/Validators";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";

export default {
	components: {SignaturePad},
	props: {
		hideClearSignature: Boolean,
		isSigned: Boolean,
	},
	data() {
		return {
			findings: '',
			signatureSvg: '',
		};
	},
	computed: {
		Validators: () => Validators,
	},
	watch: {
		// eslint-disable-next-line
		findings(newVal,oldVal) {
			this.$emit('update:findings', newVal)
		},
		// eslint-disable-next-line
		signatureSvg(newVal, oldVal) {
			this.$emit('update:signatureSvg', newVal)
		},
	},
	methods: {
		clearSignature() {
			this.$refs.signature.clearSignature();
		},
		submit() {
			if (!this.$refs.form.validate()) {
				return ;
			}
			this.$emit('submit');
		},
	},
}
</script>