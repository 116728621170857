<template>
	<data-loader
		:watch="imot"
		:callback="()=>loadData(imot)"
		:data.sync="data"
	>
		<template #default="{loading}">
			<div v-if="loading" class="text-center pa-4">
				<v-progress-circular indeterminate />
			</div>
			<div v-else-if="data">

				<v-simple-table dense>
					<thead>
					<tr>
						<th></th>
						<th>Тип, Номер, Локация</th>
						<th>Модел</th>
						<th>Дата на монтаж</th>
						<th>Дата за подмяна</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(device,index) in data.devices" :key="index">
						<td width="1%"><device-type-icon :type="device.device_type_code" /></td>
						<td>
							<v-icon color="green" v-if="device.model && device.model.is_radio==='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
							{{ device.device_N }}<br>
							<span class="grey--text">{{ DeviceTypeLabels.get(device.device_type_code) }} - {{ device.room }}</span>
						</td>
						<td>{{ device.model && `${device.model.brand} ${device.model.model}` }}</td>
						<td><date-format :date="device.mount_date" /></td>
						<td><date-format :date="device.reinstall_date" past-class="red white--text pa-1 rounded my-1" /></td>
						<td width="400">
							<v-combobox
								:items="device.up_reasons"
								v-model="values[device.id]"
								placeholder="Причина"
								class="ma-2"
								style="width: 100%; max-width: 400px;"
								hide-details="auto"
								outlined dense clearable
							/>
						</td>
					</tr>
					</tbody>
				</v-simple-table>

				<v-divider v-if="signature" />
				<div class="px-4 pt-2" v-if="signature">
					<div class="">Подпис:</div>
					<signature-pad
						v-model="signature_svg"
						ref="signature"
						:is-signed="isSigned"
						@update:isSigned="$emit('update:isSigned', $event)"
						width="100%" height="320px"
						required
					/>
					<div class="text-right mt-2" v-if="!hideClearSignature">
						<v-btn small color="primary" @click="clearSignature" :disabled="signature_svg === ''">
							Изчисти
						</v-btn>
					</div>
				</div>

			</div>
		</template>
	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import DeviceTypeIcon
	from "@/ittijs/components/DeviceDataList/DeviceTypeIcon.vue";
import {DeviceTypeLabels} from "@/ittijs/Nomenclatures";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {SignaturePad, DeviceTypeIcon, DateFormat, DataLoader},
	mixins: [
		SelectedUtilityMixin,
	],
	props: {
		imot: {},
		deviceCount: {}, // not used, only for sync
		signature: Boolean, // show signature pad
		upType: {
			type: String,
			default: 'regular',
		},
		hideClearSignature: Boolean,
		isSigned: Boolean,
	},
	data(){
		return {
			data: null,
			values: {},
			signature_svg: '',
			DeviceTypeLabels,
		}
	},
	computed: {
		valuesArray(){
			return Object.keys(this.values)
				.filter(id => this.values[id])
				.map(id => ({
					device: id,
					reason: this.values[id],
				}))
		},
	},
	watch: {
		valuesArray(val){
			this.$emit('update:deviceCount', val.length);
		},
	},
	methods: {
		clearSignature() {
			this.$refs.signature.clearSignature();
		},
		async loadData(imot){
			if (!imot) return null;
			const result = await model.loadDataForImot(this.selectedUtility, imot);
			this.values = {};
			for (const item of result.devices) {
				if (item.is_expired) {
					this.$set(this.values, item.id, item.up_expire_reason);
					//this.values[item.id] = item.up_expire_reason;
				}
			}
			return result;
		},
		save(){ // called from outside
			return model.saveDataForImot(
				this.selectedUtility,
				this.imot,
				this.valuesArray,
				{
					up_type: this.upType,
					...(this.signature ? {signature_svg: this.signature_svg} : {}),
				},
			);
		},
	},
}
</script>